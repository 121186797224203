<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/长电4G38版/images/长电4G38版小黄人_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G38版/images/长电4G38版小黄人_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G38版/images/长电4G38版小黄人_03.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "长电4G--38版",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>